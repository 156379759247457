import React from "react"
import { Link } from "gatsby"
import { useMatch } from "@reach/router"
import DownloadBox from "./DownloadBox"

const Sidebar = ({ withContactForm = false }) => {

  return (
    <div className="services-details-info">
      <ul className="services-list">
        <li>
          <Link style={{ transition: "none" }} to="/versandsoftware/"
                className={useMatch("/versandsoftware/") && "active"}>
            Übersicht
          </Link>
        </li>
        <li>
          <Link style={{ transition: "none" }} to="/versandsoftware/netversys/carrierauswahl/"
                className={useMatch("/versandsoftware/netversys/carrierauswahl/") && "active"}>
            Regelb. Carrierauswahl
          </Link>
        </li>
        <li>
          <Link style={{ transition: "none" }} to="/versandsoftware/netversys/mandanten/"
                className={useMatch("/versandsoftware/netversys/mandanten/") && "active"}>
            Mandanten
          </Link>
        </li>
        <li>
          <Link style={{ transition: "none" }} to="/versandsoftware/netversys/unified-tracktrace/"
                className={useMatch("/versandsoftware/netversys/unified-tracktrace/") && "active"}>
            Unified Track&Trace
          </Link>
        </li>
        <li>
          <Link style={{ transition: "none" }} to="/versandsoftware/netversys/vollautomatisierung/"
                className={useMatch("/versandsoftware/netversys/vollautomatisierung/") && "active"}>
            Vollautomatisierung
          </Link>
        </li>
        <li>
          <Link style={{ transition: "none" }} to="/versandsoftware/netversys/versandemails/"
                className={useMatch("/versandsoftware/netversys/versandemails/") && "active"}>
            Versandemails
          </Link>
        </li>
        <li>
          <Link style={{ transition: "none" }} to="/versandsoftware/netversys/plugins/"
                className={useMatch("/versandsoftware/netversys/plugins/") && "active"}>
            Plugins / Dokumente
          </Link>
        </li>
        <li>
          <Link style={{ transition: "none" }} to="/versandsoftware/netversys/entgeltmanagement/"
                className={useMatch("/versandsoftware/netversys/entgeltmanagement/") && "active"}>
            Entgeltmanagement
          </Link>
        </li>
      </ul>

      <div className={"d-none d-lg-block"}>
        <DownloadBox />
      </div>

      {
        withContactForm &&
        <div className="services-contact-info">
          <h3>Contact Info</h3>

          <ul>
            <li>
              <div className="icon">
                <i className="bx bx-user-pin" />
              </div>
              <span>Phone:</span>
              <a href="tel:+21453545413">+2145 354 5413</a>
            </li>
            <li>
              <div className="icon">
                <i className="bx bx-map" />
              </div>
              <span>Location:</span>
              New York, USA
            </li>
            <li>
              <div className="icon">
                <i className="bx bx-envelope" />
              </div>
              <span>Email:</span>
              <a href="mailto:hello@tarn.com">hello@tarn.com</a>
            </li>
          </ul>
        </div>
      }
    </div>
  )
}

export default Sidebar
