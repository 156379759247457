import React from "react"
import { Col, Row } from "react-bootstrap"

const WichtigeMerkmale = ({items, image, title = 'Wichtige Merkmale'}) => {
  return (
    <Row className="align-items-center">
      <Col lg={6} md={6}>
        <div className="image">
          {image}
        </div>
      </Col>

      <Col lg={6} md={6}>
        <div className="content">
          <h3>{ title }</h3>
          <ul>
            {items.map((item) => (
                <li key={item}>{item}</li>
              )
            )}
          </ul>
        </div>
      </Col>
    </Row>
  )
}

export default WichtigeMerkmale
