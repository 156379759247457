import React from "react"
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Versandemails from "../../../components/ProductDetails/netversys/Versandemails"
import Seo from "../../../components/App/Seo"

const VersandemailsPage = () => {
  return (
    <Layout>
      <Seo title={"Sendungsbestätigung via Versandemails in Perfektion"}
           description={"Versand- und Status-Emails als Kundenservice sind fester " +
           "Bestandteil im E-Commerce. NETVERSYS bietet " +
           "eigene Versandemails an die Empfänger zu verschicken."}
           image={"/images/og/netversys-main-og.png"}>
        <script type="application/ld+json">{`
              {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                  "@type": "ListItem",
                  "position": 1,
                  "name": "Home",
                  "item": "https://logentis.de"
                },{
                  "@type": "ListItem",
                  "position": 2,
                  "name": "Versandsoftware",
                  "item": "https://logentis.de/versandsoftware/"
                },{
                  "@type": "ListItem",
                  "position": 3,
                  "name": "Versandemails",
                  "item": "https://logentis.de/versandsoftware/netversys/versandemails/"
                }]
              }
              `}
        </script>
      </Seo>
      <Navbar />
      <Versandemails/>
      <Footer />
    </Layout>
  )
}

export default VersandemailsPage
