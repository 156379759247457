import React from "react"

const DownloadBox = () => {
  return (
    <div className={"download-file"}>
      <h3>Downloads / Broschüren</h3>

      <ul>
        <li>
          <a href="/dl/netversys_flyer.pdf">
            NETVERSYS Produktflyer <i className="bx bxs-file-pdf"/>
          </a>
        </li>
        <li>
          <a href="/dl/casestudy_aswo.pdf">
            Case Study: ASWO International <i className="bx bxs-file-txt"/>
          </a>
        </li>
        <li>
          <a href="/dl/casestudy_gcgruppe.pdf">
            Case Study: GC Gruppe <i className="bx bxs-file-txt"/>
          </a>
        </li>
        <li>
          <a href="/dl/intelligentes_give_away_management.pdf">
            Intelligentes Give-Away Management <i className="bx bxs-file-txt"/>
          </a>
        </li>
        <li>
          <a href="/dl/regelbasierte_carrierauswahl.pdf">
            Regelbasierte Carrierauswahl <i className="bx bxs-file-txt"/>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default DownloadBox
