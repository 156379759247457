import React from "react"
import EmailHeaderImage from "../../../assets/images/products/netversys/versandemails_header.svg"
import wmEmailSend from "../../../assets/images/products/netversys/wm-emailsend.svg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import WichtigeMerkmale from "../WichtigeMerkmale"
import LexikonBadgesRandomSection from "../../lexikon/LexikonBadgesRandomSection"
import { StaticImage } from "gatsby-plugin-image"

const Versandemails = () => {

  return (
    <>
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={EmailHeaderImage} alt="Versandemails" />
              </div>

              <div className="services-details-desc">
                <span className="sub-title">NETVERSYS</span>
                <h1 className={"pt-3"}>Versandemails</h1>
                <p>
                  Versand- und Status-Emails als Kundenservice sind fester Bestandteil im E-Commerce.
                  NETVERSYS bietet deswegen die Möglichkeit, eigene Versandmails an die Sendungsempfänger zu
                  verschicken.
                  Durch die Hilfe einer bedienerfreundlichen Oberfläche ist es mit wenigen Mausklicks möglich,
                  personifizierte und individuelle HTML5 Versand-Emails zu konfigurieren... in modernster Optik.
                </p>

                <WichtigeMerkmale items={[
                  "Automatisierter Versand von Emails",
                  "Modernes HTML5 Design",
                  "Intuitive Konfiguration",
                  "Anpassbares Layout",
                  "Mehrsprachige Templates",
                  "Protokollierter Versand",
                  "Blacklist"
                ]} image={<img src={wmEmailSend}
                               alt="Versandemails Merkmale"
                               className={"ml-lg-4 ml-0"} />} />

                <h3>Relevantes aus dem Lexikon</h3>

                <LexikonBadgesRandomSection />
              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="services-area pt-4 pb-70 bg-f1f8fb">
        <Container>
          <span className="mb-2 text-primary"><i className={"bx bxs-bullseye"} /> Im Detail</span>
          <h2>Mailing</h2>
          <Row className={"mt-5"}>
            <Col md={6} xs={12}>
              <StaticImage
                src="../../../assets/images/products/netversys/screenshots/nv_dist_mail_framed.png"
                alt="Versandemails Screenshot"
                placeholder="blurred"
                width={600}
              />
            </Col>
            <Col md={{ offset: 1, span: 5 }} xs={{ offset: 1, span: 11 }}>
              <p>
                Die Versandmails werden in einem eigenen Template mit intuitiver Eingabemaske konfiguriert. Es können
                zudem für unterschiedliche Sprachschlüssel einzelne Templates z.B. auf Deutsch und Englisch angelegt
                werden. Diese Templates können zusätzlich für jeden Mandanten eigenständig gepflegt werden.
              </p>
              <p>
                Dabei ist es kein Problem, Informationen wie TrackTace-URL, Anzahl Pakete, den genutzten Carrier oder
                die Bestellnummer in dem Template der Info-Email zu hinterlegen. NETVERSYS füllt anhand des
                Versandauftrages die passenden Daten automatisch in die finale E-Mail.
              </p>
              <p>
                Branding in Form von eigenen Logos ist natürlich eine Selbstverständlichkeit und kann ebenfalls schnell
                über die Maske angepasst werden. Sollten zusätzliche Designwünsche aufkommen, ist es weiterhin möglich
                das Template durch eigenen HTML Code anzupassen. Alle Änderungen an den Templates werden sofort an das
                Emailversandmodul propagiert, sodass der nächste Paketversand diese direkt nutzen kann.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="services-area pt-4 pb-70 bg-fafafb">
        <Container>
          <span className="mb-2 text-primary"><i className={"bx bxs-star"} /> Feature Highlight</span>
          <h2>Protokoll & Blacklist</h2>
          <Row className={"mt-5"}>
            <Col md={{ span: 5 }} xs={{ span: 12 }}>
              <p>
                Kunden haben eine Email nicht erhalten oder wünschen eine erneute Zusendung? Mit dem Emailprotokoll in
                NETVERSYS kann der Versand von Emails leicht nachvollzogen werden. Auf Bedarf können Emails erneut
                versendet werden und wenn gewünscht sogar an eine andere Adresse. Möchte der Kunde keine Emails
                erhalten, kann diesem Wunsch mit Hilfe einer Blacklist sehr einfach entsprochen werden. Durch die Pflege
                von Adressen in der Blacklist wird verhindert, dass an dem Endkunde eine Email geschickt wird.
              </p>
            </Col>
            <Col md={{ span: 6, offset: 1 }} xs={12}>
              <StaticImage
                src="../../../assets/images/products/netversys/screenshots/nv_dis_mail_blacklist_framed.png"
                alt="EMail Blacklist Screenshot"
                placeholder="blurred"
                width={600}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Versandemails
