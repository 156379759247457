import React from "react"
import LexikonBadge from "./Badge"
import { Row } from "react-bootstrap"
import { useLexikonData } from "./useLexikonData"

function LexikonBadgesRandomSection({ product = "Netversys" }) {

  const data = useLexikonData(product)

  return (
    <Row>
      {
        data && data.map((entry) => {
          return (
            <LexikonBadge key={entry.node.id} item={entry} />
          )
        })
      }
    < /Row>
  )
}

export default LexikonBadgesRandomSection
