import { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from "gatsby"

function getRandom(arr, n) {
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len)
  if (n > len)
    throw new RangeError("getRandom: more elements taken than available")
  while (n--) {
    var x = Math.floor(Math.random() * len)
    result[n] = arr[x in taken ? taken[x] : x]
    taken[x] = --len in taken ? taken[len] : len
  }
  return result
}

export function useLexikonData(product = "Netversys") {
  const [data, setData] = useState(null);

  const queryData = useStaticQuery(graphql`
    query BenutzermanagementQuery {
      qa: allMarkdownRemark(
        filter: {
          fields: {collection: {eq: "qa"}}, 
        }) { 
        edges {
          node {
            id
            frontmatter {
              question
              category
            }
            fields {
              slug
            }
          }
        }
      }            
    }`
  )

  useEffect(() => {
    if(!data) {
      const calculatedCategoryBasedOnProduct = product === "Netversys" ?
        "Versandlogistik" : product === "Netstorsys" ?
          "Warehouse Management" : "Logistik / Allgemein"

      const entries = queryData.qa.edges.filter((entry) => {
        return entry.node.frontmatter.category === calculatedCategoryBasedOnProduct
      })

      setData(getRandom(entries, 4));
    } else {
      console.log("data already there");
    }
  },  [data, product, queryData.qa.edges]);

  return data;
}
